import { useEffect, useState } from 'react';

function useOnScreen(target?: Element): boolean {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    if (target) {
      observer.observe(target);
    }

    return () => {
      observer.disconnect();
    };
  }, [target]);

  return isIntersecting;
}

export default useOnScreen;
