import { useCallback, useState } from 'react';
import { useOnScreen } from 'students/views/shared/hooks';

export default function useTaskPreview(): {
  ref: (el: HTMLDivElement) => void;
  isVisible: boolean;
} {
  const [element, setElement] = useState<HTMLDivElement>();
  const onRefSet = useCallback((node: HTMLDivElement) => {
    setElement(node);
  }, []);
  const isVisible = useOnScreen(element);

  return { ref: onRefSet, isVisible };
}
