import React, { ComponentType } from 'react';
import { ILesson, tLessonTask } from '@adeptlms/lingu-students-react-shared';
import setupAppInterface from 'students/appInterface/setupAppInterface';
import {
  tSubjectComponentProps,
  tSubjectContainerType
} from 'students/views/pages/LessonTasks/TaskSubjects';
import CheckTaskContainer from 'students/views/pages/LessonTasks/TaskSubjects/Check';
import LearnTaskContainer from 'students/views/pages/LessonTasks/TaskSubjects/Learn';
import PlayTaskContainer from 'students/views/pages/LessonTasks/TaskSubjects/Play';
import { tTaskData } from '../types';
import useTaskPreview from './useTaskPreview';

const mockHandler = (): Promise<unknown> =>
  new Promise(() => {
    /* empty */
  });

interface IProps {
  data: tTaskData;
  onNext?: () => Promise<unknown>;
  onFinish?: () => Promise<unknown>;
  onFinishAndNext?: () => Promise<unknown>;
  onSubmitTaskItem?: () => Promise<unknown>;
}

const TASK_MAP = new Map<
  tLessonTask['subject'] | undefined,
  tSubjectContainerType | null
>([
  ['teach', LearnTaskContainer],
  ['test', CheckTaskContainer],
  ['engage', PlayTaskContainer],
  [undefined, null]
]);

setupAppInterface();

const TaskPreview: React.FC<IProps> = ({
  data,
  onNext = mockHandler,
  onFinish = mockHandler,
  onFinishAndNext = mockHandler,
  onSubmitTaskItem = mockHandler
}) => {
  const TaskToRender = TASK_MAP.get(
    data.task.subject
  ) as ComponentType<tSubjectComponentProps>;
  const { ref, isVisible } = useTaskPreview();

  return (
    <div ref={ref}>
      {isVisible ? (
        <TaskToRender
          lesson={(data?.lesson as ILesson) || null}
          lessonPhrases={data.lessonPhrases}
          task={data.task}
          taskSession={data.taskSession}
          isLoading={false}
          isCompleting={false}
          onNext={onNext}
          onFinish={onFinish}
          onFinishAndNext={onFinishAndNext}
          onSubmitTaskItem={onSubmitTaskItem}
        />
      ) : null}
    </div>
  );
};

export default TaskPreview;
