import { IReturnValue } from '../../hooks/useDataFetch';
import { tTaskData } from '../types';

export default function useTaskPreviewContainer(
  taskData: string
): IReturnValue<tTaskData> {
  const data = JSON.parse(taskData);

  return { data, status: 'retrieved' };
}
