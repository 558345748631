import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import withSRootStyle from 'students/views/shared/HOCs/withSRootStyle';
import { AudioProvider } from 'students/views/shared/bundles/audio';
import PromptProvider from 'students/views/shared/providers/Prompt/PromptProvider';
import { I18nProvider } from 'i18n';

import TaskPreview from './TaskPreview';
import useTaskPreviewContainer from './useTaskPreviewContainer';
import { PreviewGlobalStyle } from '../SPreviewGlobalStyle';
import { mockStore } from '../helpers';
import 'students/styles/styles.scss';

interface IPreview {
  taskData: string;
  handlers: {
    onTaskFinished?: () => void;
  };
}

const TaskPreviewContainer: FC<IPreview> = ({ taskData, handlers }) => {
  const { data, status } = useTaskPreviewContainer(taskData);
  async function finishAndNextHandler() {
    handlers.onTaskFinished?.();
  }

  if (status === 'retrieved' && data) {
    const taskPreviewData = {
      ...data,
      lesson: { color: '' }
    };

    return (
      <Provider store={mockStore}>
        <BrowserRouter>
          <AudioProvider>
            <PromptProvider>
              {/* TODO temporary work around bug when locale is not en there is an infinite loop of locale changing */}
              <I18nProvider overrideLocaleWith="en">
                <PreviewGlobalStyle />
                <TaskPreview
                  data={taskPreviewData}
                  onFinishAndNext={finishAndNextHandler}
                  onNext={finishAndNextHandler}
                />
              </I18nProvider>
            </PromptProvider>
          </AudioProvider>
        </BrowserRouter>
      </Provider>
    );
  }

  return null;
};

export default withSRootStyle(TaskPreviewContainer);
