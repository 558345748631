import { createGlobalStyle } from 'styled-components';

export const PreviewGlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
  }

  body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.15);
  }

  body::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.65);
  }
  
  :root {
    --heading-text-color: #2D2D3A;
    
    --assignments-card-background: linear-gradient(180deg, #ECECF7 0%, rgba(236, 236, 247, 0.9) 100%);
    --assignments-card-background-mobile: linear-gradient(180deg, #ECECF7 0%, rgba(236, 236, 247, 0.9) 100%);
    --assignments-card-shadow: 0 0 10px rgba(11, 63, 79, 0.26);
    --assignments-message-in-background: #FFFFFF;
    --assignments-message-in-background-hover: rgba(255, 255, 255, 0.5);
    --assignments-message-out-background: #D9D7E5;
    --assignments-tag-background: rgba(0, 0, 0, 0.2);
    --assignments-feedback-message-background: #FFFFFF;
    --assignments-reply-input-background: rgba(193, 192, 210, 0.5);
    --assignments-skip-button-background: rgba(45, 45, 58, 0.1);
    --assignments-skip-button-color: rgba(45, 45, 58, 0.8);

    --wave-audio-player-icon-background: transparent;
  }
`;
