import 'extensions/axiosHelper';
import setupSentry from 'extensions/setupSentry';
import 'extensions/webp_detect';

import 'students/styles/fonts/fonts.css';
import 'students/styles/styles.scss';

import { createRoot } from 'react-dom/client';
import TaskPreviewContainer from './Preview/TaskPreview/TaskPreviewContainer';

setupSentry();

// function importBuildTarget() {
//   switch (window.location.pathname) {
//     case 'lesson':
//       return import('./Preview/LessonPreview/LessonPreview');
//     case 'task':
//     default:
//       return import('./Preview/TaskPreview/TaskPreviewContainer');
//   }
// }

function runPreviewTaskApp(taskData: string, onTaskFinished: () => void) {
  console.log('FRAME PREVIEW: runPreviewTaskApp with task data: ', taskData);

  const root = createRoot(document.getElementById('lingu-app-preview')!);
  root.render(<TaskPreviewContainer taskData={taskData} handlers={{ onTaskFinished }} />);
}

setupResize();
setupPreview();

function setupPreview() {
  window.addEventListener('message', (e) => {
    if (e.data.type === 'task_data') {
      console.log('FRAME PREVIEW: received task data', e.data.payload);
      runPreviewTaskApp(e.data.payload, () => {
        window.parent.postMessage({ type: 'task_finished', payload: null }, '*');
      });
    }
  });
  console.log('FRAME PREVIEW: listen for messages and send ready to listen');
  window.parent.postMessage({ type: 'ready_to_listen', payload: null }, '*');
}

function setupResize() {
  function talkToParent(
    type: 'resize',
    message: {
      height: number;
      width: number;
    }
  ) {
    window.parent.postMessage(
      {
        type,
        data: message
      },
      '*'
    );
  }
  window.addEventListener('resize', (e: any) => {
    if (e?.target?.innerWidth) {
      document.body.style.width = `${e.target.innerWidth}px`;
    }
    talkToParent('resize', {
      height: document.body.scrollHeight,
      width: document.body.scrollWidth
    });
  });
  window.addEventListener('load', (e: any) => {
    if (e?.target?.innerWidth) {
      document.body.style.width = `${e.target.innerWidth}px`;
    }
    talkToParent('resize', {
      height: document.body.scrollHeight,
      width: document.body.scrollWidth
    });
  });
}
